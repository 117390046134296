import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import userRepository from '../../repositories/UserRepository';
import { Menu, MenuItem, Button } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import decksRepository from '../../repositories/DecksRepository';
import cardsRepository from '../../repositories/CardsRepository';
import { LOGIN_PATH, MAIN_PATH, PROFILE_PATH, REGISTER_PATH } from '../App/AppPaths';

type LoginMenuState = {
    isAuthenticated: boolean,
    userName?: string,
}

export default function LoginMenu() {
    const history = useHistory();
    const [loginState, setLoginState] = useState<LoginMenuState>({ isAuthenticated: false });
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement>();

    useEffect(() => {
        const populateState = async () => {
            const user = userRepository.getUser();
            setLoginState({
                isAuthenticated: userRepository.isAuthenticated(),
                userName: user ? user.userName : undefined,
            });
        }
        populateState();
        const subscriptionId = userRepository.subscribe(() => populateState());
        return () => userRepository.unsubscribe(subscriptionId);
    }, []);

    const openMenu = (e: React.MouseEvent<HTMLElement>) => setMenuAnchor(e.currentTarget);
    const closeMenu = () => setMenuAnchor(undefined);

    const openProfile = () => {
        closeMenu();
        history.push(PROFILE_PATH);
    }

    const profileLogout = () => {
        closeMenu();
        userRepository.logout();
        decksRepository.resetLocalRepository();
        cardsRepository.resetLocalRepository();
        history.push(MAIN_PATH);
    }

    return (
        loginState.isAuthenticated && loginState.userName
            ?
            <Fragment>
                <Button className={menuAnchor ? 'active' : ''} onClick={openMenu} >
                    {loginState.userName}
                    <AccountCircle className='icon' />
                </Button>
                <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)}
                    onClose={closeMenu} style={{ margin: '50px 0 0 10px' }}>
                    <MenuItem onClick={openProfile} children={'Профиль'} />
                    <MenuItem onClick={profileLogout} children={'Выйти'} />
                </Menu>
            </Fragment>
            :
            <Fragment>
                <Button children={'Регистрация'} onClick={() => history.push(REGISTER_PATH)} />
                <Button children={'Вход'} onClick={() => history.push(LOGIN_PATH)} />
            </Fragment>
    );
}
