import { useHistory } from 'react-router-dom';
import DeckEntity from '../../entities/Deck';
import { useRef } from 'react';
import userRepository from '../../repositories/UserRepository';
import {
  Button,
  ButtonGroup,
  makeStyles,
  Tooltip,
  TooltipProps,
} from '@material-ui/core';
import { Modes } from '../CardsStudy/Mode/TypesForMode';
import { useEffect } from 'react';
import {
  AllInclusive,
  TouchApp,
  Spellcheck,
  ViewCarousel,
  MenuBook,
  Error,
  PlayArrowRounded,
  Visibility,
  Settings,
} from '@material-ui/icons';

type DeckPreviewOverlayProps = {
  deck: DeckEntity;
  EditorComponent: () => JSX.Element | null;
};

enum StudyTypes {
  Classic,
  Endless,
}

const TooltipForOverlay = (props: TooltipProps) => {
  const useStyles = makeStyles(() => ({
    tooltip: {
      backgroundColor: '#6C989C',
      color: 'white',
      fontWeight: 'bold',
      fontSize: 14,
    },
    arrow: {
      color: '#6C989C',
    },
  }));
  return (
    <Tooltip classes={useStyles()} arrow disableFocusListener {...props} />
  );
};

const setDataFromLocalStorage = (
  data: string,
  deckId: string,
  value: number
) => {
  const user = userRepository.getUser();
  const key = user ? `${data}~${user.id}~${deckId}` : `${data}~ANON~${deckId}`;
  localStorage.setItem(key, value.toString());
};

const getDataFromLocalStorage = (
  data: string,
  deckId: string,
  defaultValue: number
) => {
  const user = userRepository.getUser();
  const key = user ? `${data}~${user.id}~${deckId}` : `${data}~ANON~${deckId}`;
  const item = localStorage.getItem(key);
  return item ? parseInt(item) : defaultValue;
};

export default function DeckPreviewOverlay(props: DeckPreviewOverlayProps) {
  const { deck, EditorComponent } = props;
  const history = useHistory();

  const mode = useRef<Modes>(0);
  const studyType = useRef<StudyTypes>(0);

  const ModesButtons = () => {
    const refButtonGroup = useRef<HTMLDivElement>(null);
    const setMode = (newMode: Modes) => {
      const buttons = refButtonGroup.current?.querySelectorAll('button');
      if (buttons) {
        buttons.forEach((b) => b.classList.remove('active'));
        buttons[newMode].classList.add('active');
        mode.current = newMode;
        setDataFromLocalStorage('mode', deck.id, newMode);
      }
    };
    useEffect(() => setMode(getDataFromLocalStorage('mode', deck.id, 0)), []);
    return (
      <ButtonGroup ref={refButtonGroup} size="large">
        <TooltipForOverlay title="Обычный режим">
          <Button
            children={<ViewCarousel />}
            tabIndex={-1}
            onClick={() => setMode(Modes.Basic)}
          />
        </TooltipForOverlay>
        <TooltipForOverlay title="Режим матчинга">
          <Button
            children={<TouchApp />}
            tabIndex={-1}
            onClick={() => setMode(Modes.Selection)}
          />
        </TooltipForOverlay>
        <TooltipForOverlay title="Режим письма">
          <Button
            children={<Spellcheck />}
            tabIndex={-1}
            onClick={() => setMode(Modes.Writing)}
          />
        </TooltipForOverlay>
      </ButtonGroup>
    );
  };

  const StudyTypeButtons = () => {
    const refButtonGroup = useRef<HTMLDivElement>(null);
    const setStudyType = (newStudyTypes: StudyTypes) => {
      const buttons = refButtonGroup.current?.querySelectorAll('button');
      if (buttons) {
        buttons.forEach((b) => b.classList.remove('active'));
        buttons[newStudyTypes].classList.add('active');
        studyType.current = newStudyTypes;
        setDataFromLocalStorage('type', deck.id, newStudyTypes);
      }
    };
    useEffect(
      () => setStudyType(getDataFromLocalStorage('type', deck.id, 1)),
      []
    );
    return (
      <ButtonGroup ref={refButtonGroup} size="large">
        {userRepository.isAuthenticated() ? (
          <TooltipForOverlay title="Обучение с интервалами">
            <Button
              children={<MenuBook />}
              tabIndex={-1}
              onClick={() => setStudyType(StudyTypes.Classic)}
            />
          </TooltipForOverlay>
        ) : (
          <TooltipForOverlay title="Требуется авторизация">
            <Button children={<Error />} tabIndex={-1} />
          </TooltipForOverlay>
        )}
        <TooltipForOverlay title="Без ограничений">
          <Button
            children={<AllInclusive />}
            tabIndex={-1}
            onClick={() => setStudyType(StudyTypes.Endless)}
          />
        </TooltipForOverlay>
      </ButtonGroup>
    );
  };

  const LinkButtons = () => {
    const handleStart = () => {
      const modeLink = Modes[mode.current].toLowerCase();
      const studyTypeLink = StudyTypes[studyType.current].toLowerCase();
      if (studyType.current === StudyTypes.Classic) {
        history.push(`/${modeLink}-study/${deck.id}`);
      } else history.push(`/${modeLink}-study-${studyTypeLink}/${deck.id}`);
    };
    const handleSettings = () => history.push(`/cards-preview/${deck.id}`);
    return (
      <ButtonGroup size="large" style={{ marginTop: '60px' }}>
        <TooltipForOverlay title="Начать обучение">
          <Button
            children={<PlayArrowRounded />}
            tabIndex={-1}
            onClick={handleStart}
          />
        </TooltipForOverlay>
        {userRepository.getUser()?.id === deck.authorId ? (
          <TooltipForOverlay title="Настройки карточек">
            <Button
              children={<Settings />}
              tabIndex={-1}
              onClick={handleSettings}
            />
          </TooltipForOverlay>
        ) : (
          <TooltipForOverlay title="Превью карточек">
            <Button
              children={<Visibility />}
              tabIndex={-1}
              onClick={handleSettings}
            />
          </TooltipForOverlay>
        )}
      </ButtonGroup>
    );
  };

  return (
    <div className="overlay">
      <h1 style={{ color: 'white' }}>Режим обучения</h1>
      <ModesButtons />
      <h1 style={{ color: 'white', marginTop: '50px' }}>Тип обучения</h1>
      <StudyTypeButtons />
      <LinkButtons />
      <EditorComponent />
    </div>
  );
}
