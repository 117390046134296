import { useState, useEffect } from 'react';
import { LinearProgress, LinearProgressProps, styled } from '@material-ui/core';
import userRepository from '../../repositories/UserRepository';
import cardsRepository from '../../repositories/CardsRepository';
import DeckEntity from '../../entities/Deck';

type DeckPreviewProgressProps = {
    deck: DeckEntity,
}

type ValueState = {
    current: number,
    max: number
}

interface ProgressBarProps {
    color: 'gray' | 'red' | 'yellow' | 'green';
}

const ProgressBar = styled(
    ({ color: color2, ...other }: ProgressBarProps
        & Omit<LinearProgressProps, keyof ProgressBarProps>) => (
        <LinearProgress {...other} />
    ),
)({
    borderRadius: '2.5px',
    width: '75%',
    height: '5px',
    marginBottom: '5px',
    backgroundColor: ({ color }: ProgressBarProps) => {
        switch (color) {
            case 'red':
                return 'rgb(255 122 115 / 60%)';
            case 'yellow':
                return 'rgb(255 211 126 / 60%)';
            case 'green':
                return 'rgb(100 222 137 / 60%);';
            default:
                return '#80808078';
        }
    },
    '& .MuiLinearProgress-bar': {
        backgroundColor: ({ color }: ProgressBarProps) => {
            switch (color) {
                case 'red':
                    return '#FF0D00';
                case 'yellow':
                    return '#FED201';
                case 'green':
                    return '#00BD39';
                default:
                    return '#80808078';
            }
        },
    }
});

export default function DeckPreviewProgress(props: DeckPreviewProgressProps) {
    const { deck } = props;
    const [value, setValue] = useState<ValueState>(
        { current: deck.cardsCountStudied[userRepository.getUser()!.id] ?? 0, max: deck.cardsCount });

    useEffect(() => {
        if (!cardsRepository.containsDeck(deck.id)) {
            return;
        }
        cardsRepository.getCardsFromDeck(deck.id)
            .then(cards => {
                const filteredCards = cards.filter(
                    c => new Date() > new Date(c.timeToRepeat[userRepository.getUser()!.id] ?? 0));
                setValue({ current: cards.length - filteredCards.length, max: cards.length });
            });
    }, []);

    const getPercentage = ({ current, max }: ValueState) =>
        current < 0 ? -1 : Math.round(current / max * 100);

    const getColor = (percentage: number) => {
        if (percentage > 0 && percentage < 100) {
            return 'yellow';
        }
        switch (percentage) {
            case 0:
                return 'red';
            case 100:
                return 'green';
            default:
                return 'gray';
        }
    }

    const getTextColor = (percentage: number) => {
        if (percentage > 0 && percentage < 100) {
            return '#FED201';
        }
        switch (percentage) {
            case 0:
                return 'rgb(255 122 115 / 60%)';
            case 100:
                return '#00BD39';
            default:
                return 'gray';
        }
    }

    return (
        <div className='progress'>
            <ProgressBar color={getColor(getPercentage(value))} variant='determinate' value={getPercentage(value)} />
            <span style={{ color: getTextColor(getPercentage(value)), fontSize: 'small', fontWeight: 'bold' }}>
                {value.current === -1 ? 'Загрузка...' : `${value.current}/${value.max}`}
            </span>
        </div>
    );
}