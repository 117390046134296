import { useEffect, useRef, useState } from 'react';
import { InferProps } from 'prop-types';
import { CardEntity, CreateEmptyCard } from "../../entities/Card";
import LoaderLayout from '../LoaderLayout/LoaderLayout';
import cardsRepository from '../../repositories/CardsRepository';
import { Card } from '../Card/Card';
import randomCards from './RandomCards';
import BasicMode from './Mode/BasicMode';
import WritingMode from './Mode/WritingMode';
import SelectionMode from './Mode/SelectionMode';
import { Modes } from './Mode/TypesForMode';

type CardsEndlessStudyProps = {
    deckId: string,
    mode: Modes,
}

export default function CardsEndlessStudy({ deckId, mode }: InferProps<CardsEndlessStudyProps>) {
    const [isLoading, setIsLoading] = useState(true);
    const [isNotFound, setIsNotfound] = useState(false);

    const divRef = useRef<HTMLDivElement>(null);

    const getNextCard = useRef(randomCards([]));
    const cardsRef = useRef<CardEntity[]>([]);
    const [currentCard, setCurrentCard] = useState<CardEntity>(CreateEmptyCard());

    useEffect(() => {
        cardsRepository.getCardsFromDeck(deckId)
            .then(cards => {
                cardsRef.current = cards;
                getNextCard.current = randomCards(cards);
                if (cards.length === 0) {
                    setIsNotfound(true);
                }
                setIsLoading(false);
            });
    }, []);

    const getNext = async () => {
        const nextCard = getNextCard.current();
        if (nextCard.id === '') {
            getNextCard.current = randomCards(cardsRef.current);
            const nextCard = getNextCard.current();
            setCurrentCard(nextCard);
            return nextCard;
        }
        else setCurrentCard(nextCard);
        return nextCard;
    }

    const getMode = (mode: Modes) => {
        switch (mode) {
            case Modes.Basic:
                return <BasicMode divRef={divRef} getNextCard={getNext} />;
            case Modes.Selection:
                return <SelectionMode divRef={divRef} getNextCard={getNext} />;
            case Modes.Writing:
                return <WritingMode divRef={divRef} getNextCard={getNext} />;
            default:
                return <div style={{margin: '50px'}}>Режим управления не существует</div>
        }
    }

    const componentNotFound = (
        <div className='centerText'>
            Карточек еще нет
        </div>
    );

    return (
        <LoaderLayout isLoading={isLoading} isNotFound={isNotFound} componentNotFound={componentNotFound}>
            <div className='cardsStudy'>
                <Card ref={divRef} card={currentCard} />
                {getMode(mode)}
            </div>
        </LoaderLayout>
    );
}