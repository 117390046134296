export const RequiredErrorMessage = [
    'Это поле не может быть пустым',
];

export const PasswordErrorMessages = [
    'Это поле не может быть пустым',
    'Пароль должен содержать минимум 8 символов',
    'Пароль должен содержать хотя бы одно число',
    'Пароль должен содержать хотя бы одну латинскую букву в нижнем регистре',
    'Пароль должен содержать хотя бы одну латинскую букву в верхнем регистре',
];

export const RepeatPasswordErrorMessages = [
    'Это поле не может быть пустым',
    'Пароли не совпадают',
];

export const DeckNameErrorMessages = [
    'Это поле не может быть пустым',
    'Название не должен содержать спецсимволы',
];

export const DeckDescriptionErrorMessages = [
    'Описание должно состоять не больше 150 символов',
];

export const CardWordErrorMessages = [
    'Это поле не может быть пустым',
    'Слово не должно содержать спецсимволы',
    'Описание должно состоять не больше 100 символов',
];