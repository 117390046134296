import "./App.css";
import { useState, useEffect } from 'react';
import { Route, Redirect, Switch } from "react-router";
import { Layout } from "./Layout";
import MainDecks from "../Decks/MainDecks";
import CardsPreviewPage from "../CardsPreviewPage/CardsPreviewPage";
import CustomDecks from "../Decks/CustomDecks";
import Register from "../Authorization/Register";
import Login from "../Authorization/Login";
import Profile from "../Profile/Profile";
import userRepository from "../../repositories/UserRepository";
import NotFound from "../NotFound/NotFound";
import CardsStudy from "../CardsStudy/CardsStudy";
import CardsEndlessStudy from "../CardsStudy/CardsEndlessStudy";
import { Modes } from "../CardsStudy/Mode/TypesForMode";
import {
    BASIC_STUDY_ENDLESS_PATH, BASIC_STUDY_PATH, CARDS_PREVIEW_PATH,
    CUSTOM_DECKS_PATH, LOGIN_PATH, MAIN_PATH, PROFILE_PATH, REGISTER_PATH,
    SEARCH_PATH, SELECTION_STUDY_ENDLESS_PATH, SELECTION_STUDY_PATH,
    WRITING_STUDY_ENDLESS_PATH, WRITING_STUDY_PATH
} from "./AppPaths";

export default function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(userRepository.isAuthenticated());

    useEffect(() => {
        const updateState = () => {
            setIsAuthenticated(userRepository.isAuthenticated());
        }
        updateState();
        const subscribe = userRepository.subscribe(updateState);
        return () => userRepository.unsubscribe(subscribe);
    }, []);

    return (
        <Layout>
            <Switch>
                {isAuthenticated ? [
                    <Redirect key='Redirect0' from={REGISTER_PATH} to={MAIN_PATH} />,
                    <Redirect key='Redirect1' from={LOGIN_PATH} to={MAIN_PATH} />,
                ] : [
                    <Redirect key='Redirect2' from={CUSTOM_DECKS_PATH} to={LOGIN_PATH} />,
                    <Redirect key='Redirect3' from={PROFILE_PATH} to={LOGIN_PATH} />,
                    <Redirect key='Redirect4' from={BASIC_STUDY_PATH} to={LOGIN_PATH} />,
                    <Redirect key='Redirect5' from={SELECTION_STUDY_PATH} to={LOGIN_PATH} />,
                    <Redirect key='Redirect6' from={WRITING_STUDY_PATH} to={LOGIN_PATH} />,
                ]}
                <Route exact path={MAIN_PATH} component={MainDecks} />
                <Route path={REGISTER_PATH} component={Register} />
                <Route path={LOGIN_PATH} component={Login} />
                <Route path={PROFILE_PATH} component={Profile} />
                <Route path={CUSTOM_DECKS_PATH} component={CustomDecks} />
                <Route path={SEARCH_PATH} render={(props) =>
                    <MainDecks key={props.location.search} />
                } />
                <Route path={BASIC_STUDY_PATH + '/:id'} render={(props) =>
                    <CardsStudy deckId={props.match.params.id} mode={Modes.Basic} />
                } />
                <Route path={SELECTION_STUDY_PATH + '/:id'} render={(props) =>
                    <CardsStudy deckId={props.match.params.id} mode={Modes.Selection} />
                } />
                <Route path={WRITING_STUDY_PATH + '/:id'} render={(props) =>
                    <CardsStudy deckId={props.match.params.id} mode={Modes.Writing} />
                } />
                <Route path={BASIC_STUDY_ENDLESS_PATH + '/:id'} render={(props) =>
                    <CardsEndlessStudy deckId={props.match.params.id} mode={Modes.Basic} />
                } />
                <Route path={SELECTION_STUDY_ENDLESS_PATH + '/:id'} render={(props) =>
                    <CardsEndlessStudy deckId={props.match.params.id} mode={Modes.Selection} />
                } />
                <Route path={WRITING_STUDY_ENDLESS_PATH + '/:id'} render={(props) =>
                    <CardsEndlessStudy deckId={props.match.params.id} mode={Modes.Writing} />
                } />
                <Route path={CARDS_PREVIEW_PATH + '/:id'} render={(props) =>
                    <CardsPreviewPage deckId={props.match.params.id} />
                } />
                <Route path='*' component={NotFound} />
            </Switch>
        </Layout>
    );
}