import './NotFound.css';

const NotFound = () => {
    return (
        <div className="notFound">
            <img src="/images/notFound/notfound.svg" alt="Упс... Здесь ничего не найдено."/>
        </div>
    )
}

export default NotFound;