import { Button, TextField, Fab } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { Fragment, useEffect, useRef } from 'react';
import { useState } from 'react';
import { CreateEmptyCard } from '../../../entities/Card';
import { defaultModeState, ModeProps, ModeState } from './TypesForMode';

export default function WritingMode(props: ModeProps) {
  const { divRef, onTrue, onFalse, getNextCard } = props;

  const currentCard = useRef(CreateEmptyCard());

  const [modeState, setModeState] = useState<ModeState>(defaultModeState);
  const [inputState, setInputState] = useState<string>('');

  useEffect(() => {
    getNextCard().then((nextCard) => {
      currentCard.current = nextCard;
    });
  }, []);

  function chooseAnswer(isKnow: boolean) {
    if (!isKnow) {
      setModeState({ ...modeState, isWaiting: false });
      divRef.current?.classList.add('isFlipped');
      if (onFalse) {
        onFalse(currentCard.current.id);
      }
      setTimeout(
        () => setModeState({ ...modeState, isKnow, isWaiting: true }),
        750
      );
    } else setModeState({ ...modeState, isKnow });
  }

  function checkAnswer(answer: string) {
    setModeState({ ...modeState, isWaiting: false });
    divRef.current?.classList.add('isFlipped');

    if (currentCard.current.answer.toLowerCase() !== answer.toLowerCase()) {
      if (onFalse) {
        onFalse(currentCard.current.id);
      }
      setTimeout(
        () => setModeState({ ...modeState, isKnow: false, isWaiting: true }),
        750
      );
      return;
    }

    if (onTrue) {
      onTrue(currentCard.current.id);
    }

    setTimeout(() => {
      moveNextCard();
    }, 1500);
  }

  function moveNextCard() {
    setModeState({ ...modeState, isWaiting: false });
    setInputState('');
    divRef.current?.classList.add('isReduce');

    setTimeout(async () => {
      const nextCard = await getNextCard();
      if (nextCard.id === '') {
        return;
      }
      currentCard.current = nextCard;
      divRef.current?.classList.remove('isReduce', 'isFlipped');
      setTimeout(() => setModeState(defaultModeState), 750);
    }, 750);
  }

  return modeState.isWaiting ? (
    <Fragment>
      {modeState.isKnow === undefined ? (
        <div>
          <Button
            className="answerButton"
            variant="contained"
            color="primary"
            onClick={() => chooseAnswer(true)}
          >
            Знаю
          </Button>
          <Button
            className="answerButton"
            variant="contained"
            color="primary"
            onClick={() => chooseAnswer(false)}
          >
            Не знаю
          </Button>
        </div>
      ) : modeState.isKnow ? (
        <div className="flexCenter">
          <TextField
            className="answerInput"
            variant="outlined"
            color="primary"
            value={inputState}
            autoFocus
            placeholder="Ответ"
            InputProps={{
              style: { backgroundColor: 'white' },
            }}
            onChange={(e) => setInputState(e.target.value)}
            onKeyDown={(e) =>
              e.key === 'Enter' ? checkAnswer(inputState) : null
            }
          />
          <Fab
            className="checkButton"
            color="primary"
            onClick={() => checkAnswer(inputState)}
          >
            <Send />
          </Fab>
        </div>
      ) : (
        <Button
          className="answerButton"
          variant="contained"
          color="primary"
          onClick={moveNextCard}
        >
          Продолжить
        </Button>
      )}
    </Fragment>
  ) : null;
}
