export const RequiredValidator = [
    'required',
];

export const PasswordValidators = [
    'required',
    'minStringLength:8',
    'matchRegexp:(?=.*[0-9])',
    'matchRegexp:(?=.*[a-z])',
    'matchRegexp:(?=.*[A-Z])',
];

export const RepeatPasswordValidators = [
    'required',
    'isPasswordMatch',
];

export const DeckNameValidators = [
    'required',
];

export const DeckDescriptionValidators = [
    'maxStringLength:150',
];

export const CardWordValidators = [
    'required',
    'maxStringLength:100',
];
