import { RefObject } from "react"
import { CardEntity } from "../../../entities/Card"

export type ModeState = {
    isKnow: boolean | undefined,
    isWaiting: boolean,
}

export type ModeProps = {
    divRef: RefObject<HTMLDivElement>,
    getNextCard: () => Promise<CardEntity>,
    onTrue?: (cardId: string) => void,
    onFalse?: (cardId: string) => void,
}

export const defaultModeState: ModeState = {
    isKnow: undefined,
    isWaiting: true,
}

export enum Modes {
    Basic,
    Selection,
    Writing,
}