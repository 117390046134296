import { Button } from "@material-ui/core";
import { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import { CreateEmptyCard } from "../../../entities/Card";
import { defaultModeState, ModeProps, ModeState } from "./TypesForMode";

export default function BasicMode(props: ModeProps) {
    const { divRef, onTrue, onFalse, getNextCard } = props;

    const currentCard = useRef(CreateEmptyCard());

    const [modeState, setModeState] = useState<ModeState>(defaultModeState);

    useEffect(() => {
        getNextCard().then(nextCard => {
            currentCard.current = nextCard;
        });
    }, []);

    function chooseAnswer(isKnow: boolean) {
        setModeState({ ...modeState, isWaiting: false });
        divRef.current?.classList.add('isFlipped');
        if (!isKnow && onFalse) {
            onFalse(currentCard.current.id);
        }
        setTimeout(() => setModeState({ ...modeState, isKnow, isWaiting: true }), 750);
    }

    function checkAnswer(isRight: boolean) {
        setModeState({ ...modeState, isWaiting: false });
        divRef.current?.classList.add('isFlipped');
        if (onTrue && isRight) {
            onTrue(currentCard.current.id);
        }
        if (onFalse && !isRight) {
            onFalse(currentCard.current.id);
        }
        moveNextCard();
    }

    function moveNextCard() {
        setModeState({ ...modeState, isWaiting: false });
        divRef.current?.classList.add('isReduce');
        setTimeout(async () => {
            const nextCard = await getNextCard();
            if (nextCard.id === '') {
                return;
            }
            currentCard.current = nextCard;
            divRef.current?.classList.remove('isReduce', 'isFlipped');
            setTimeout(() => setModeState(defaultModeState), 750);
        }, 750);
    }

    return (
        modeState.isWaiting
            ?
            <Fragment>
                {modeState.isKnow === undefined
                    ?
                    <div>
                        <Button className='answerButton'
                            variant='contained' color='primary' onClick={() => chooseAnswer(true)}>
                            Знаю
                        </Button>
                        <Button className='answerButton'
                            variant='contained' color='primary' onClick={() => chooseAnswer(false)}>
                            Не знаю
                        </Button>
                    </div>
                    :
                    modeState.isKnow
                        ?
                        <div>
                            <Button className='answerButton'
                                variant='contained' color='primary' onClick={() => checkAnswer(true)}>
                                Точно знаю
                            </Button>
                            <Button className='answerButton'
                                variant='contained' color='primary' onClick={() => checkAnswer(false)}>
                                Ошибся
                            </Button>
                        </div>
                        :
                        <Button className='answerButton'
                            variant='contained' color='primary' onClick={moveNextCard}>
                            Продолжить
                        </Button>
                }
            </Fragment>
            : null
    );
}