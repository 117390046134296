import './DeckPreview.css';
import { DeleteButton } from '../CustomButtons/DeleteButton';
import DeckEntity from '../../entities/Deck';
import { Fragment, useRef } from 'react';
import userRepository from '../../repositories/UserRepository';
import Chip from '@material-ui/core/Chip';
import stringToHSL from '../../helpers/stringToHSL';
import DeckPreviewOverlay from './DeckPreviewOverlay';
import DeckPreviewProgress from './DeckPreviewProgress';
import { IconButton, makeStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

type DeckPreviewProps = {
  deck: DeckEntity;
  onDelete?: (deckId: string) => void;
  openSetting?: (deckId: string) => void;
};

const useStyles = makeStyles({
  editButton: {
    position: 'absolute',
    right: '45px',
    top: '5px',
    color: '#6C989C',
  },
});

export default function DeckPreview(props: DeckPreviewProps) {
  const { deck, onDelete, openSetting } = props;
  const сomponentForDelete = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const TagsComponent = () => (
    <div className="tags">
      {deck.tags.map((t, i) => (
        <Chip
          key={i}
          label={t}
          size="small"
          style={{ backgroundColor: stringToHSL(t) }}
        />
      ))}
    </div>
  );

  const PreviewComponent = () => {
    if (deck.imagePath) {
      return (
        <div className="textBlock">
          <img src={deck.imagePath} alt="preview" />
          <h1 className="name">{deck.name}</h1>
        </div>
      );
    }
    return (
      <div className="textBlock">
        <h1 className="name">{deck.name}</h1>
        <p>{deck.description}</p>
      </div>
    );
  };

  const EditorComponent = () => {
    if (
      onDelete &&
      openSetting &&
      userRepository.getUser()?.id === deck.authorId
    ) {
      return (
        <Fragment>
          <DeleteButton
            refComponentForDelete={сomponentForDelete}
            onClick={() => onDelete(deck.id)}
            tabIndex={-1}
            warningMessage="Вы действительно хотите удалить данный набор?"
          />
          ;
          <IconButton
            className={classes.editButton}
            children={<Edit />}
            onClick={() => openSetting(deck.id)}
            tabIndex={-1}
          />
        </Fragment>
      );
    }
    return null;
  };

  return (
    <div ref={сomponentForDelete} className="deckPreview flexCenter">
      <PreviewComponent />
      {userRepository.isAuthenticated() ? (
        <DeckPreviewProgress deck={deck} />
      ) : null}
      <TagsComponent />
      <DeckPreviewOverlay deck={deck} EditorComponent={EditorComponent} />
    </div>
  );
}
