import { ApiPaths } from "./ApiCardsettaConstants";
import userRepository from "./UserRepository";
import DeckEntity from "../entities/Deck";
import DeckPageEntity from "../entities/DeckPage";
import DeckPatcher from "../helpers/DeckPatcher";

class DecksRepository {
    private _pages: Map<string, DeckPageEntity> = new Map();
    private _myDecks: Map<string, DeckEntity> = new Map();

    private _nextUpdatePagesDate = Date.now();
    private _nextUpdateMyDecksDate = Date.now();

    private _subscriptions: { id: number, callback: () => void }[] = [];
    private _nextSubscriptionId: number = 0;

    async getDeck(id: string) {
        const deck = this._myDecks.get(id);
        if (deck) {
            return deck;
        }
        const response = await fetch(ApiPaths.decks.byId(id));
        if (response.status === 200) {
            return await response.json() as DeckEntity;
        }
        return undefined;
    }

    async getDecksPage(pageNumber: number, pageSize: number, otherQueryParams: string = '') {
        const mapKey = `?pageNumber=${pageNumber}&pageSize=${pageSize}&${otherQueryParams}`;

        if (Date.now() > this._nextUpdatePagesDate) {
            this._nextUpdatePagesDate = Date.now() + 1000 * 60 * 2;
            this._pages.clear();
        }

        if (this._pages.has(mapKey)) {
            return this._pages.get(mapKey);
        }

        const response = await fetch(ApiPaths.decks.default + mapKey);

        if (response.status === 200) {
            const page = await response.json() as DeckPageEntity;
            this._pages.set(mapKey, page);
            return page;
        }
        return undefined;
    }

    async getMyDecks() {
        if (Date.now() > this._nextUpdateMyDecksDate) {
            this._nextUpdateMyDecksDate = Date.now() + 1000 * 60 * 4;
            this._myDecks.clear();
        }

        if (this._myDecks.size > 0) {
            const decks: DeckEntity[] = []
            this._myDecks.forEach(d => decks.push(d));
            return decks;
        }

        const body = {};
        userRepository.addAuthorizationHeader(body);
        const response = await fetch(ApiPaths.decks.my, body);

        if (response.status === 200) {
            const decks = await response.json() as DeckEntity[];
            decks.forEach(d => this._myDecks.set(d.id, d));
            return decks;
        }
        return [];
    }

    async addDeck(deck: DeckEntity, img?: File) {
        const formData = new FormData();
        formData.append('name', deck.name);
        formData.append('description', deck.description);
        deck.tags.forEach(tag => formData.append('tags[]', tag));
        if (img) {
            formData.append('image', img, img.name);
        }
        const data = {
            method: 'POST',
            body: formData
        }
        userRepository.addAuthorizationHeader(data);
        const response = await fetch(ApiPaths.decks.default, data);
        if (response.status === 201) {
            const newDeck = await response.json() as DeckEntity;
            if (this._myDecks.size !== 0) {
                this._myDecks.set(newDeck.id, newDeck);
            }
            const user = userRepository.getUser()
            if (user) {
                userRepository.updateLocalUser({ ...user, deckIds: [...user.deckIds, newDeck.id] })
            }
            this._notifySubscribers();
        }
    }

    async updateDeck(deck: DeckEntity, img?: File) {
        const oldDeck = await this.getDeck(deck.id);
        if (!oldDeck) {
            return;
        }

        if (this._myDecks.size !== 0) {
            this._myDecks.set(deck.id, deck);
        }
        this._notifySubscribers();

        await this._updateDeck(deck, oldDeck);
        await this._updateDeckTags(deck, oldDeck);
        await this._updateDeckImage(deck, oldDeck, img);
    }

    async removeDeck(deckId: string) {
        const user = userRepository.getUser()
        if (user) {
            userRepository.updateLocalUser({ ...user, deckIds: user.deckIds.filter(i => i !== deckId) })
        }
        this._myDecks.delete(deckId);
        this._notifySubscribers();

        const body = {
            method: 'DELETE'
        };
        userRepository.addAuthorizationHeader(body);
        await fetch(ApiPaths.decks.byId(deckId), body);
    }

    resetLocalRepository() {
        this._pages.clear();
        this._myDecks.clear();
    }

    subscribe(callback: () => void) {
        this._subscriptions.push({ id: this._nextSubscriptionId++, callback });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId: number) {
        const subscriptions = this._subscriptions.filter(e => e.id !== subscriptionId);
        if (this._subscriptions.length === subscriptions.length) {
            throw new Error(`Invalid subscription ID - ${subscriptionId}`);
        }
        this._subscriptions = subscriptions;
    }

    private _notifySubscribers() {
        this._pages.clear();
        this._subscriptions.forEach(s => s.callback());
    }

    private async _updateDeck(deck: DeckEntity, oldDeck: DeckEntity) {
        const patchBuilder = new DeckPatcher();
        if (oldDeck.name !== deck.name) {
            patchBuilder.patchName(deck.name);
        }
        if (oldDeck.description !== deck.description) {
            patchBuilder.patchDescription(deck.description);
        }
        const data = patchBuilder.build();
        if (data.length === 0) {
            return false;
        }
        const body: RequestInit = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json-patch+json'
            },
            body: JSON.stringify(data)
        };
        userRepository.addAuthorizationHeader(body);
        const response = await fetch(ApiPaths.decks.byId(deck.id), body);
        return response.status === 204;
    }

    private async _updateDeckTags(deck: DeckEntity, oldDeck: DeckEntity) {
        if (oldDeck.tags === deck.tags) {
            return false;
        }
        const body: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json-patch+json'
            },
            body: JSON.stringify(deck.tags)
        };
        userRepository.addAuthorizationHeader(body);
        const response = await fetch(ApiPaths.decks.updateTags(deck.id), body);
        return response.status === 204;
    }

    private async _updateDeckImage(deck: DeckEntity, oldDeck: DeckEntity, img?: File) {
        if (!img || oldDeck.imagePath === deck.imagePath) {
            return false;
        }
        const formData = new FormData();
        formData.append('image', img, img.name);
        const body = {
            method: 'POST',
            body: formData
        }
        userRepository.addAuthorizationHeader(body);
        const response = await fetch(ApiPaths.decks.updateImage(deck.id), body);
        return response.status === 204;
    }
}

const decksRepository = new DecksRepository();
export default decksRepository;