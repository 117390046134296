import './CardsPreviewPage.css';
import { InferProps } from 'prop-types';
import { Dialog, DialogContent, Fab, makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Fragment, useEffect, useState } from 'react';
import { CardEntity } from '../../entities/Card';
import userRepository from '../../repositories/UserRepository';
import CardPreview from './CardPreview';
import LoaderLayout from '../LoaderLayout/LoaderLayout';
import cardsRepository from '../../repositories/CardsRepository';
import AddCard from '../CardSettings/AddCard';
import EditCard from '../CardSettings/EditCard';

const useStyles = makeStyles({
  fabOne: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    backgroundColor: '#6C989C',
    color: 'white',
    '&:hover': {
      backgroundColor: '#335153',
    },
  },
  settings: {
    '& .MuiDialog-paper': {
      borderRadius: '25px',
    },
    '& .MuiDialogContent-root': {
      padding: '0',
    },
  },
});

type СardsPreviewPageProps = {
  deckId: string;
};

type CardsState = {
  isLoading: boolean;
  cards: CardEntity[];
};

type SettingState = {
  isOpen: boolean;
  cardId?: string;
};

export default function СardsPreviewPage({
  deckId,
}: InferProps<СardsPreviewPageProps>) {
  const classes = useStyles();
  const [cardsState, setCardsState] = useState<CardsState>({
    isLoading: true,
    cards: [],
  });
  const [settingsState, setSettingsState] = useState<SettingState>({
    isOpen: false,
  });

  const isAuthorAuth =
    userRepository.isAuthenticated() &&
    !!userRepository.getUser()?.deckIds.includes(deckId);

  useEffect(() => {
    const updateCards = async () => {
      const cards = await cardsRepository.getCardsFromDeck(deckId);
      setCardsState({ isLoading: false, cards });
    };
    updateCards();
    const subscriptionId = cardsRepository.subscribe(updateCards);
    return () => cardsRepository.unsubscribe(subscriptionId);
  }, []);

  const handleRemoveCard = async (cardId: string) => {
    await cardsRepository.removeCardFromDeck(deckId, cardId);
  };

  const getFabButton = () => (
    <Fab
      className={classes.fabOne}
      color="primary"
      children={<Add />}
      onClick={() => handleOpenSettings()}
    />
  );

  const handleOpenSettings = (cardId?: string) =>
    setSettingsState({ isOpen: true, cardId });

  const handleCloseSettings = () =>
    setSettingsState({ ...settingsState, isOpen: false });

  return (
    <Fragment>
      <LoaderLayout
        className="cardsPreview"
        isLoading={cardsState.isLoading}
        isNotFound={cardsState.cards.length === 0}
        componentNotFound={<div className="centerText">Карточек еще нет</div>}
      >
        {cardsState.cards.map((card) => (
          <CardPreview
            key={card.id}
            card={card}
            isAuth={isAuthorAuth}
            openSetting={handleOpenSettings}
            onDelete={handleRemoveCard}
          />
        ))}
      </LoaderLayout>
      <Dialog
        className={classes.settings}
        maxWidth={false}
        open={settingsState.isOpen}
        onClose={handleCloseSettings}
      >
        <DialogContent>
          {settingsState.cardId ? (
            <EditCard
              deckId={deckId}
              cardId={settingsState.cardId}
              onClose={handleCloseSettings}
            />
          ) : (
            <AddCard deckId={deckId} onClose={handleCloseSettings} />
          )}
        </DialogContent>
      </Dialog>
      {isAuthorAuth ? getFabButton() : null}
    </Fragment>
  );
}
