import { Button } from "@material-ui/core";
import { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import { CreateEmptyCard } from "../../../entities/Card";
import { defaultModeState, ModeProps, ModeState } from "./TypesForMode";

const getRandomNumber = (max: number) => Math.floor(Math.random() * max);

async function getRussianWords() {
    const response = await fetch('https://raw.githubusercontent.com/svetlitskiy/wordlist-russian/master/russian-words.json');
    const words = await response.json() as string[];
    return words;
}

async function getEnglishWords() {
    const response = await fetch('https://raw.githubusercontent.com/bevacqua/correcthorse/master/wordlist.json');
    const words = await response.json() as string[];
    return words;
}

function createGeneratorWords(minLength: number, maxLength: number) {
    const russianWords: string[] = [];
    const englishWords: string[] = [];
    getRussianWords().then(words => words.forEach(w => russianWords.push(w)));
    getEnglishWords().then(words => words.forEach(w => englishWords.push(w)));

    const getRandomWord = (word: string): string => {
        let w = '';
        if (RegExp('\\w*[a-zA-Z]\\w*').test(word)) {
            w = englishWords[getRandomNumber(englishWords.length)];
        }
        else w = russianWords[getRandomNumber(russianWords.length)];
        if (w.length < minLength || w.length > maxLength) {
            return getRandomWord(word);
        }
        return w[0].toUpperCase() + w.slice(1);
    };

    return (word: string, count: number): string[] => {
        const words = [];
        for (let i = 0; i < count; i++) {
            words.push(getRandomWord(word));
        }
        words.splice(getRandomNumber(count + 1), 0, word);
        return words;
    }
}

const randomWords = createGeneratorWords(5, 10);

export default function WritingMode(props: ModeProps) {
    const { divRef, onTrue, onFalse, getNextCard } = props;

    const currentCard = useRef(CreateEmptyCard());

    const [modeState, setModeState] = useState<ModeState>(defaultModeState);
    const [buttonState, setButtonState] = useState<string[]>(['', '', '']);

    useEffect(() => {
        getNextCard().then(nextCard => {
            currentCard.current = nextCard;
            setButtonState(randomWords(nextCard.answer, 2));
        });
    }, []);

    function chooseAnswer(isKnow: boolean) {
        if (!isKnow) {
            setModeState({ ...modeState, isWaiting: false });
            divRef.current?.classList.add('isFlipped');
            if (onFalse) {
                onFalse(currentCard.current.id);
            }
            setTimeout(() => setModeState({ ...modeState, isKnow, isWaiting: true }), 750);
        }
        else setModeState({ ...modeState, isKnow });
    }

    function checkAnswer(answer: string) {
        setModeState({ ...modeState, isWaiting: false });
        divRef.current?.classList.add('isFlipped');

        if (currentCard.current.answer.toLowerCase() !== answer.toLowerCase()) {
            if (onFalse) {
                onFalse(currentCard.current.id);
            }
            setTimeout(() =>
                setModeState({ ...modeState, isKnow: false, isWaiting: true })
                , 750);
            return;
        }

        if (onTrue) {
            onTrue(currentCard.current.id);
        }

        setTimeout(() => {
            moveNextCard();
        }, 1500);
    }

    function moveNextCard() {
        setModeState({ ...modeState, isWaiting: false });
        divRef.current?.classList.add('isReduce');

        setTimeout(async () => {
            const nextCard = await getNextCard();
            if (nextCard.id === '') {
                return;
            }
            currentCard.current = nextCard;
            setButtonState(randomWords(nextCard.answer, 2));
            divRef.current?.classList.remove('isReduce', 'isFlipped');
            setTimeout(() => setModeState(defaultModeState), 750);
        }, 750);
    }

    return (
        modeState.isWaiting
            ?
            <Fragment>
                {modeState.isKnow === undefined
                    ?
                    <div>
                        <Button className='answerButton'
                            variant='contained' color='primary' onClick={() => chooseAnswer(true)}>
                            Знаю
                        </Button>
                        <Button className='answerButton'
                            variant='contained' color='primary' onClick={() => chooseAnswer(false)}>
                            Не знаю
                        </Button>
                    </div>
                    :
                    modeState.isKnow
                        ?
                        <div className='flexCenter'>
                            <Button className='answerButton' key={`ab0${buttonState[0]}`}
                                variant='contained' color='primary' onClick={() => checkAnswer(buttonState[0])}>
                                {buttonState[0]}
                            </Button>
                            <Button className='answerButton' key={`ab1${buttonState[1]}`}
                                variant='contained' color='primary' onClick={(e) => checkAnswer(buttonState[1])}>
                                {buttonState[1]}
                            </Button>
                            <Button className='answerButton' key={`ab2${buttonState[2]}`}
                                variant='contained' color='primary' onClick={(e) => checkAnswer(buttonState[2])}>
                                {buttonState[2]}
                            </Button>
                        </div>
                        :
                        <Button className='answerButton'
                            variant='contained' color='primary' onClick={moveNextCard}>
                            Продолжить
                        </Button>
                }
            </Fragment>
            : null
    );
}