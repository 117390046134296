import PasswordInput from './PasswordInput'
import { InputAdornment } from '@material-ui/core';
import { ChangeEvent, Fragment, useState } from 'react';
import { AccountCircle } from '@material-ui/icons';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import userRepository from "../../repositories/UserRepository";
import { useHistory } from "react-router-dom";
import LoginAlerts, { LoginAlertsState, CreateDefaultState } from '../Alerts/LoginAlerts';
import { LoadingButton } from '../CustomButtons/LoadingButton';
import { RequiredValidator } from '../../validators/Validators';
import { RequiredErrorMessage } from '../../validators/ErrorMessage';
import { MAIN_PATH } from '../App/AppPaths';

type LoginForm = {
    login: string,
    password: string,
}

export default function Login() {
    const history = useHistory();
    const [loginForm, setLoginForm] = useState<LoginForm>({ login: '', password: '' });
    const [alertsState, setAlertsState] = useState<LoginAlertsState>(CreateDefaultState());
    const [isLoading, setIsLoding] = useState(false);

    const onSubmit = async () => {
        setIsLoding(true);
        const isSuccess = await userRepository.login(loginForm.login, loginForm.password);
        if (isSuccess) {
            history.push(MAIN_PATH);
        }
        else {
            setIsLoding(false);
            setAlertsState({ ...alertsState, isLoginOrPasswordError: true })
        }
    }

    return (
        <Fragment>
            <ValidatorForm className='authorization' onSubmit={onSubmit}>
                <h1>Вход в аккаунт</h1>
                <TextValidator className='input' label='Логин'
                    variant='outlined' type='text' name='login'
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setLoginForm({ ...loginForm, login: e.target.value })}
                    value={loginForm.login}
                    validators={RequiredValidator}
                    errorMessages={RequiredErrorMessage}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        ),
                    }}
                />
                <PasswordInput className='input' label='Пароль'
                    name='password' value={loginForm.password}
                    validators={RequiredValidator}
                    errorMessages={RequiredErrorMessage}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setLoginForm({ ...loginForm, password: e.target.value })}
                />
                <LoadingButton loading={isLoading} text='Войти' type='submit' />
            </ValidatorForm>
            <LoginAlerts alertsState={alertsState}
                onClose={(state: LoginAlertsState) => setAlertsState(state)} />
        </Fragment>
    );
}