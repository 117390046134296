import { Dialog, DialogContent, Fab, makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Fragment, useEffect, useState } from 'react';
import DeckEntity from '../../entities/Deck';
import Decks from './Decks';
import decksRepository from '../../repositories/DecksRepository';
import AddDeck from '../DeckSettings/AddDeck';
import EditDeck from '../DeckSettings/EditDeck';

const useStyles = makeStyles({
  fabOne: {
    position: 'fixed',
    right: '20px',
    bottom: '20px',
    backgroundColor: '#6C989C',
    color: 'white',
    '&:hover': {
      backgroundColor: '#335153',
    },
  },
  settings: {
    '& .MuiDialog-paper': {
      borderRadius: '25px',
    },
    '& .MuiDialogContent-root': {
      padding: '0',
    },
  },
});

type DecksState = {
  isLoading: boolean;
  isNotFound: boolean;
  decks: DeckEntity[];
};

type SettingState = {
  isOpen: boolean;
  deckId?: string;
};

export default function CustomDecks() {
  const classes = useStyles();
  const [decksState, setDecksState] = useState<DecksState>({
    isLoading: true,
    isNotFound: false,
    decks: [],
  });
  const [settingsState, setSettingsState] = useState<SettingState>({
    isOpen: false,
  });

  useEffect(() => {
    const updateDecks = async () => {
      const decks = await decksRepository.getMyDecks();
      setDecksState({
        isLoading: false,
        isNotFound: decks.length === 0,
        decks: [...decks],
      });
    };
    updateDecks();
    const subscriptionId = decksRepository.subscribe(updateDecks);
    return () => decksRepository.unsubscribe(subscriptionId);
  }, []);

  const handleDelete = async (deckId: string) => {
    await decksRepository.removeDeck(deckId);
  };

  const handleOpenSettings = (deckId?: string) =>
    setSettingsState({ isOpen: true, deckId });

  const handleCloseSettings = () =>
    setSettingsState({ ...settingsState, isOpen: false });

  return (
    <Fragment>
      <Decks
        {...decksState}
        onDelete={handleDelete}
        openSetting={handleOpenSettings}
      />
      <Dialog
        className={classes.settings}
        maxWidth={false}
        open={settingsState.isOpen}
        onClose={handleCloseSettings}
      >
        <DialogContent>
          {settingsState.deckId ? (
            <EditDeck
              deckId={settingsState.deckId}
              onClose={handleCloseSettings}
            />
          ) : (
            <AddDeck onClose={handleCloseSettings} />
          )}
        </DialogContent>
      </Dialog>
      <Fab
        className={classes.fabOne}
        children={<Add />}
        onClick={() => handleOpenSettings()}
      />
    </Fragment>
  );
}
