import './NavMenu.css';
import { useHistory } from 'react-router-dom';
import LoginMenu from './LoginMenu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import userRepository from '../../repositories/UserRepository';
import { useEffect, useState } from 'react';
import TagInput from '../TagInput/TagInput';
import { Button } from '@material-ui/core';
import { CUSTOM_DECKS_PATH, MAIN_PATH } from '../App/AppPaths';

export default function NavMenu() {
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setIsAuthenticated(userRepository.isAuthenticated());
    const subscriptionId = userRepository.subscribe(() =>
      setIsAuthenticated(userRepository.isAuthenticated())
    );
    return () => userRepository.unsubscribe(subscriptionId);
  }, []);

  return (
    <AppBar className="header" position="static">
      <Toolbar className="toolbar">
        {isAuthenticated ? (
          <div className="flexCenter" style={{ justifyContent: 'flex-start' }}>
            <Button
              children="Мои наборы"
              onClick={() => history.push(CUSTOM_DECKS_PATH)}
            />
          </div>
        ) : (
          ''
        )}

        <div className={`flexCenter ${!isAuthenticated ? 'span2' : ''}`}>
          <TagInput />
        </div>

        <div className="flexCenter">
          <div
            className="logo-container"
            onClick={() => history.push(MAIN_PATH)}
          >
            <img src="/images/logo.png" alt="logo" />
            {/* <img className='hov' src='/images/logo/logo_gold.svg' alt='logo' /> */}
          </div>
        </div>

        <div></div>

        <div className="flexCenter" style={{ justifyContent: 'flex-end' }}>
          <LoginMenu />
        </div>
      </Toolbar>
    </AppBar>
  );
}
