export default interface DeckEntity {
    id: string,
    authorId: string,
    name: string,
    description: string,
    imagePath: string,
    tags: string[],
    cardsCount: number,
    cardsCountStudied: Record<string, number>
}

export const CreateEmptyDeck = (): DeckEntity => {
    return {
        id: '',
        authorId: '',
        name: '',
        description: '',
        imagePath: '',
        tags: [],
        cardsCount: 0,
        cardsCountStudied: {}
    };
};