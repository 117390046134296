import { CardEntity, CreateEmptyCard } from "../../entities/Card";

const randomCards = (cards: CardEntity[]) => {
    let copyOfCards = [...cards];
    return (): CardEntity => {
        if (copyOfCards.length === 0) {
            return CreateEmptyCard();
        }
        const card = copyOfCards[Math.floor(Math.random() * copyOfCards.length)];
        copyOfCards = copyOfCards.filter(c => c.id !== card.id);
        return card;
    }
}

export default randomCards;