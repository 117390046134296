import './Profile.css';
import PasswordInput from '../Authorization/PasswordInput';
import { InputAdornment } from '@material-ui/core';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { AccountCircle } from '@material-ui/icons';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
  RequiredValidator,
  PasswordValidators,
  RepeatPasswordValidators,
} from '../../validators/Validators';
import {
  RequiredErrorMessage,
  PasswordErrorMessages,
  RepeatPasswordErrorMessages,
} from '../../validators/ErrorMessage';
import userRepository from '../../repositories/UserRepository';
import ProfileAlerts, {
  ProfileAlertsState,
  CreateDefaultState,
} from '../Alerts/ProfileAlerts';
import { LoadingButton } from '../CustomButtons/LoadingButton';

type ProfileForm = {
  login: string;
  oldPassword: string;
  password: string;
  repeatPassword: string;
};

export default function Profile() {
  const [userName, setUserName] = useState('');
  const [profileForm, setProfileForm] = useState<ProfileForm>({
    login: userName,
    oldPassword: '',
    password: '',
    repeatPassword: '',
  });
  const [alertsState, setAlertsState] = useState<ProfileAlertsState>(
    CreateDefaultState()
  );

  const [isLoadingLogin, setIsLodingLogin] = useState(false);
  const [isLoadingPassword, setIsLodingPassword] = useState(false);

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value: string) => {
      return value === profileForm.password;
    });

    return () => {
      ValidatorForm.removeValidationRule('isPasswordMatch');
    };
  }, [profileForm.password]);

  useEffect(() => {
    const updateUserName = () => {
      const user = userRepository.getUser();
      if (user !== null) {
        setUserName(user.userName);
        setProfileForm({ ...profileForm, login: user.userName });
      }
    };
    updateUserName();
    const _subscribe = userRepository.subscribe(updateUserName);
    return () => userRepository.unsubscribe(_subscribe);
  }, []);

  async function handleChangeLogin() {
    setIsLodingLogin(true);
    const isSuccess = await userRepository.updateUserName(profileForm.login);
    if (isSuccess) {
      setAlertsState({ ...alertsState, isLoginSuccess: true });
    } else {
      setAlertsState({ ...alertsState, isLoginError: true });
    }
    setIsLodingLogin(false);
  }

  async function handleChangePassword() {
    setIsLodingPassword(true);
    const isSuccess = await userRepository.updatePassword(
      profileForm.oldPassword,
      profileForm.password,
      profileForm.repeatPassword
    );
    if (isSuccess) {
      setAlertsState({ ...alertsState, isPasswordSuccess: true });
      setProfileForm((value) => ({
        ...value,
        oldPassword: '',
        password: '',
        repeatPassword: '',
      }));
    } else setAlertsState({ ...alertsState, isPasswordError: true });
    setIsLodingPassword(false);
  }
  return (
    <Fragment>
      <div className="profile">
        <Fragment>
          <h1>Профиль {userName}</h1>
          <ValidatorForm
            className="form"
            onSubmit={handleChangeLogin}
            instantValidate={false}
          >
            <TextValidator
              className="input"
              label="Логин"
              variant="outlined"
              type="text"
              name="login"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setProfileForm({ ...profileForm, login: e.target.value })
              }
              value={profileForm.login}
              validators={RequiredValidator}
              errorMessages={RequiredErrorMessage}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
            <LoadingButton
              className="saveLogin"
              loading={isLoadingLogin}
              text="Сохранить измения"
              type="submit"
            />
          </ValidatorForm>
          <ValidatorForm
            className="form"
            onSubmit={handleChangePassword}
            instantValidate={false}
          >
            <PasswordInput
              className="input"
              label="Старый пароль"
              value={profileForm.oldPassword}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setProfileForm({ ...profileForm, oldPassword: e.target.value })
              }
              validators={PasswordValidators}
              errorMessages={PasswordErrorMessages}
            />

            <PasswordInput
              className="input"
              label="Новый пароль"
              name="password"
              value={profileForm.password}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setProfileForm({ ...profileForm, password: e.target.value })
              }
              validators={PasswordValidators}
              errorMessages={PasswordErrorMessages}
            />

            <PasswordInput
              className="input"
              label="Повторите пароль"
              value={profileForm.repeatPassword}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setProfileForm({
                  ...profileForm,
                  repeatPassword: e.target.value,
                })
              }
              validators={RepeatPasswordValidators}
              errorMessages={RepeatPasswordErrorMessages}
            />
            <LoadingButton
              className="changePassword"
              loading={isLoadingPassword}
              text="Изменить пароль"
              type="submit"
            />
          </ValidatorForm>
        </Fragment>
      </div>
      <ProfileAlerts
        alertsState={alertsState}
        onClose={(state: ProfileAlertsState) => setAlertsState(state)}
      />
    </Fragment>
  );
}
