export const MAIN_PATH = '/';
export const CUSTOM_DECKS_PATH = '/custom-decks';
export const SEARCH_PATH = '/search';

export const REGISTER_PATH = '/register';
export const LOGIN_PATH = '/login';
export const PROFILE_PATH = '/profile';

export const CARDS_PREVIEW_PATH = '/cards-preview';

export const BASIC_STUDY_PATH = '/basic-study';
export const BASIC_STUDY_ENDLESS_PATH = '/basic-study-endless';

export const SELECTION_STUDY_PATH = '/selection-study';
export const SELECTION_STUDY_ENDLESS_PATH = '/selection-study-endless';

export const WRITING_STUDY_PATH = '/writing-study';
export const WRITING_STUDY_ENDLESS_PATH = '/writing-study-endless';