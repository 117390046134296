import './DeckSettings.css';
import { ChangeEvent, useRef, useState } from 'react';
import { Button, Chip } from '@material-ui/core'
import { FileObject } from 'material-ui-dropzone'
import DeckEntity from '../../entities/Deck';
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { DeckNameValidators, DeckDescriptionValidators } from '../../validators/Validators';
import { DeckNameErrorMessages, DeckDescriptionErrorMessages } from '../../validators/ErrorMessage';
// import ChipInput from 'material-ui-chip-input'
import stringToHSL from '../../helpers/stringToHSL'
import { LoadingButton } from '../CustomButtons/LoadingButton';

type DeckSettingsProps = {
    deck: DeckEntity,
    textForSave: string,
    textForClose: string,
    onSave?: (deck: DeckEntity, img?: File) => Promise<void>,
    onClose: Function,
}

export default function DeckSettings(props: DeckSettingsProps) {
    const { deck, textForSave, textForClose, onSave, onClose } = props;

    const [deckState, setDeckState] = useState<DeckEntity>(deck);
    const [isLoading, setIsLoading] = useState(false);
    const file = useRef<File>();
    const [tagsInputValue, setTagsInputValue] = useState('');
    const filterTag = (tag: string) => tag.replace(/[^\d^a-zа-я]/g, '').slice(0, 10);

    async function handleSave() {
        if (onSave) {
            setIsLoading(true);
            await onSave(deckState, file.current);
            onClose();
        }
    }

    function handleAddImage(files: FileObject[]) {
        setDeckState({ ...deckState, imagePath: files[0].data?.toString() ?? '' });
        file.current = files[0].file;
    }

    function handleDeleteImage() {
        if (deckState.imagePath) {
            setDeckState({ ...deckState, imagePath: '' });
            file.current = undefined;
        }
    }

    const DeckDemo = () => (
        <div className='deckPreviewBlock '>
            <div className='deckPreview flexCenter'>
                {deckState.imagePath
                    ?
                    <div className='textBlock'>
                        <img src={deckState.imagePath} alt='preview' />
                        <h1 className='name'>{deckState.name}</h1>
                    </div>
                    :
                    <div className='textBlock'>
                        <h1 className='name'>{deckState.name}</h1>
                        <p>{deckState.description}</p>
                    </div>
                }
            </div>
            <div className='tags'>
                {deckState.tags.map((t, i) => <Chip key={i} label={t} size='small'
                    style={{ backgroundColor: stringToHSL(t) }} />)}
            </div>
            <div className='overlay'>
                <Button className='changeButton' tabIndex={-1}
                    children='Удалить изображение' onClick={handleDeleteImage} />
            </div>
        </div>
    );

    return (
        <div className='settingsBlock'>
            <div className='part1'>
                <DeckDemo />
            </div>
            <ValidatorForm className='part2' onSubmit={handleSave} instantValidate={false}>
                <TextValidator label='Название' variant='outlined' type='text' name='name'
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setDeckState({ ...deckState, name: e.target.value })}
                    value={deckState.name}
                    validators={DeckNameValidators}
                    errorMessages={DeckNameErrorMessages}
                />
                <TextValidator label='Описание' variant='outlined' type='text' name='description'
                    rows={4} rowsMax={4} multiline
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setDeckState({ ...deckState, description: e.target.value })}
                    value={deckState.description ?? ''}
                    validators={DeckDescriptionValidators}
                    errorMessages={DeckDescriptionErrorMessages}
                />
                {/* <ChipInput className='chipInput' variant='outlined' label='Теги для поиска'
                    newChipKeys={[' ', 'Enter']} defaultValue={deckState.tags}
                    InputProps={{
                        value: tagsInputValue,
                        onChange: (e => setTagsInputValue(filterTag(e.target.value)))
                    }}
                    onBeforeAdd={() => { setTagsInputValue(''); return true; }}
                    onChange={(tags) => setDeckState({ ...deckState, tags: tags })}
                /> */}
                <ImageDropzone className='miniDropzone' onAddImage={handleAddImage} />
                <LoadingButton className='button' type='submit' text={textForSave} loading={isLoading} />
                <Button className='button' onClick={() => onClose()} children={textForClose} />
            </ValidatorForm >
        </div >
    );
}