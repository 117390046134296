import { useEffect, useState } from 'react';
import { CardEntity, CreateEmptyCard } from "../../entities/Card";
import CardSettings from './CardSettings';
import LoaderLayout from '../LoaderLayout/LoaderLayout';
import NotFound from "../NotFound/NotFound";
import cardsRepository from '../../repositories/CardsRepository';

type EditCardProps = {
    deckId: string,
    cardId: string;
    onClose: Function,
}

type State = {
    isLoading: boolean,
    isNotFound: boolean,
    card: CardEntity
}

export default function EditCard(props: EditCardProps) {
    const { deckId, cardId, onClose } = props;
    const [state, setState] = useState<State>(
        { isLoading: true, isNotFound: false, card: CreateEmptyCard() });

    useEffect(() => {
        cardsRepository.getCardFromDeck(deckId, cardId)
            .then(card => {
                if (card) {
                    setState({ ...state, isLoading: false, card });
                } else {
                    setState({ ...state, isNotFound: true });
                }
            });
    }, []);

    async function onSave(card: CardEntity, img?: File) {
        if (deckId) {
            await cardsRepository.updateCardInDeck(deckId, card, img);
        }
    }

    return (
        <LoaderLayout isLoading={state.isLoading} isNotFound={state.isNotFound} componentNotFound={<NotFound />}>
            <CardSettings card={state.card}
                textForSave={'Сохранить'} textForClose={'Отменить'}
                onSave={onSave} onClose={onClose} />
        </LoaderLayout>
    );
}