import DeckSettings from './DeckSettings'
import DeckEntity, { CreateEmptyDeck } from '../../entities/Deck';
import { useEffect, useState } from 'react';
import LoaderLayout from '../LoaderLayout/LoaderLayout';
import NotFound from "../NotFound/NotFound";
import decksRepository from '../../repositories/DecksRepository';

type EditDeckProps = {
    deckId: string,
    onClose: Function,
}

type State = {
    isLoading: boolean,
    isNotFound: boolean,
    deck: DeckEntity
}

export default function EditDeck(props: EditDeckProps) {
    const { deckId, onClose } = props;
    const [state, setState] = useState<State>(
        { isLoading: true, isNotFound: false, deck: CreateEmptyDeck() });

    useEffect(() => {
        decksRepository.getDeck(deckId)
            .then(deck => {
                if (deck) {
                    setState({ ...state, isLoading: false, deck });
                } else {
                    setState({ ...state, isNotFound: true });
                }
            });
    }, []);

    const onSave = async (deck: DeckEntity, img?: File) => {
        await decksRepository.updateDeck(deck, img);
    }

    return (
        <LoaderLayout isLoading={state.isLoading} isNotFound={state.isNotFound} componentNotFound={<NotFound />}>
            <DeckSettings deck={state.deck}
                textForSave='Сохранить' textForClose='Отменить'
                onSave={onSave} onClose={onClose} />
        </LoaderLayout>
    );
}