import styled from '@material-ui/core/styles/styled';
import IconButton from '@material-ui/core/IconButton';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  dialogButton: {
    color: '#6C989C',
    '&:hover': {
      color: '#335153',
    },
  },
});

export const DeleteButton = styled(
  ({ onClick, refComponentForDelete, warningMessage, ...other }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    function handleDelete() {
      setOpen(false);
      if (refComponentForDelete.current) {
        const component = refComponentForDelete.current;
        component.style.transition = 'opacity 500ms';
        component.style.opacity = '0';
      }
      if (onClick) {
        setTimeout(() => onClick(), 500);
      }
    }

    return (
      <div>
        <IconButton onClick={() => setOpen(true)} {...other}>
          <DeleteForever />
        </IconButton>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>{'Предупреждение'}</DialogTitle>
          <DialogContent>
            <DialogContentText>{warningMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className={classes.dialogButton} onClick={handleDelete}>
              Да
            </Button>
            <Button
              className={classes.dialogButton}
              onClick={() => setOpen(false)}
              autoFocus
            >
              Нет
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
)({
  position: 'absolute',
  right: '5px',
  top: '5px',
  color: 'red',
});
