import DeckEntity from "../../entities/Deck";
import { useEffect, useRef, useState } from "react";
import Decks from './Decks';
import useQuery from "../../customHooks/useQuery";
import decksRepository from "../../repositories/DecksRepository";
import { CircularProgress } from "@material-ui/core";

type DecksState = {
    isLoading: boolean,
    isNotFound: boolean,
    decks: DeckEntity[],
}

const PAGE_SIZE = 9;

export default function MainDecks() {
    const query = useQuery();
    const pageNumberRef = useRef(1);
    const totalPagesRef = useRef(1);

    const [decksState, setDecksState] = useState<DecksState>(
        { isLoading: false, isNotFound: false, decks: [] });

    useEffect(() => {
        let isMounted = true;
        const updateDecks = async (pageNumber: number) => {
            if (isMounted) {
                setDecksState(value => ({ ...value, isLoading: true }));
            }
            const page = await decksRepository.getDecksPage(pageNumber, PAGE_SIZE, query.toString());
            if (!page || page.items.length === 0) {
                if (isMounted) {
                    setDecksState({ ...decksState, isLoading: false, isNotFound: true });
                }
                return;
            }
            totalPagesRef.current = page.totalPages;
            if (isMounted) {
                setDecksState(value =>
                    ({ ...value, isLoading: false, decks: [...value.decks, ...page.items] }));
            }
        }

        updateDecks(pageNumberRef.current);

        const onscroll = () => {
            const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
            if (scrollHeight - scrollTop - 100 <= clientHeight
                && pageNumberRef.current < totalPagesRef.current) {
                pageNumberRef.current++;
                updateDecks(pageNumberRef.current);
            }
        };
        document.addEventListener('scroll', onscroll);

        return () => {
            isMounted = false;
            window.removeEventListener('scroll', onscroll);
        }
    }, []);

    return (
        <div className='flexCenter' style={{ flexDirection: 'column', marginBottom: '50px' }} >
            <Decks {...decksState} isLoading={decksState.isLoading && pageNumberRef.current === 1} />
            {decksState.isLoading && pageNumberRef.current !== 1 ? <CircularProgress size={50} /> : null}
        </div>
    );
}