import { Component, Fragment } from 'react';
import NavMenu from '../NavMenu/NavMenu';

export class Layout extends Component {
    render() {
        return (
            <Fragment>
                <NavMenu />
                <div className='flexCenter'>
                    {this.props.children}
                </div>
            </Fragment >
        );
    }
}