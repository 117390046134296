import DeckSettings from './DeckSettings'
import DeckEntity, { CreateEmptyDeck } from '../../entities/Deck';
import decksRepository from '../../repositories/DecksRepository';

type AddDeckProps = {
    onClose: Function,
}

export default function AddDeck(props: AddDeckProps) {
    const { onClose } = props;

    const handleSave = async (deck: DeckEntity, img?: File) => {
        await decksRepository.addDeck(deck, img);
    }
    
    return (
        <DeckSettings deck={CreateEmptyDeck()}
            textForSave='Добавить' textForClose='Закрыть'
            onSave={handleSave} onClose={onClose} />
    );
}