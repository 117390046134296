import { CardEntity, CreateEmptyCard } from "../../entities/Card";
import cardsRepository from '../../repositories/CardsRepository';
import CardSettings from './CardSettings';

type AddCardProps = {
    deckId: string,
    onClose: Function
}

export default function AddCard(props: AddCardProps) {
    const { deckId, onClose } = props;

    const handleSave = async (card: CardEntity, img?: File) => {
        await cardsRepository.addCardInDeck(deckId, card, img);
    }

    return (
        <CardSettings card={CreateEmptyCard()}
            textForSave={'Добавить'} textForClose={'Закрыть'}
            onSave={handleSave} onClose={onClose} />
    );
}