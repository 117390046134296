import './CardsStudy.css';
import { useEffect, useRef, useState } from 'react';
import { InferProps } from 'prop-types';
import { CardEntity, CreateEmptyCard } from "../../entities/Card";
import LoaderLayout from '../LoaderLayout/LoaderLayout';
import userRepository from '../../repositories/UserRepository';
import cardsRepository from '../../repositories/CardsRepository';
import { Card } from '../Card/Card';
import WritingMode from './Mode/WritingMode';
import randomCards from './RandomCards';
import BasicMode from './Mode/BasicMode';
import { Modes } from './Mode/TypesForMode';
import SelectionMode from './Mode/SelectionMode';

type CardsStudyProps = {
    deckId: string,
    mode: Modes,
}

export default function CardsStudy({ deckId, mode }: InferProps<CardsStudyProps>) {
    const [isLoading, setIsLoading] = useState(true);
    const [isNotFound, setIsNotfound] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const divRef = useRef<HTMLDivElement>(null);

    const getNextCard = useRef(randomCards([]));
    const [currentCard, setCurrentCard] = useState<CardEntity>(CreateEmptyCard());

    useEffect(() => {
        getCards().then(cards => {
            getNextCard.current = randomCards(cards);
            setIsLoading(false);
        });
    }, []);

    const getCards = async () => {
        const cards = await cardsRepository.getCardsFromDeck(deckId);
        setIsEmpty(cards.length === 0);
        const filteredCards = cards.filter(c => new Date() > new Date(c.timeToRepeat[userRepository.getUser()!.id] ?? 0));
        setIsNotfound(filteredCards.length === 0);
        return filteredCards;
    }

    const getNext = async () => {
        const nextCard = getNextCard.current();
        if (nextCard.id === '') {
            const nextCards = await getCards();
            if (nextCards.length === 0) {
                return CreateEmptyCard();
            }
            getNextCard.current = randomCards(nextCards);
            const nextCard = getNextCard.current();
            setCurrentCard(nextCard);
            return nextCard;
        }
        else setCurrentCard(nextCard);
        return nextCard;
    }

    const getMode = (mode: Modes) => {
        const onTrue = (cardId: string) => cardsRepository.sendAnswerToCard(deckId, cardId, true); 
        const onFalse = (cardId: string) => cardsRepository.sendAnswerToCard(deckId, cardId, false); 
        switch (mode) {
            case Modes.Basic:
                return <BasicMode divRef={divRef} getNextCard={getNext} onTrue={onTrue} onFalse={onFalse} />;
            case Modes.Selection:
                return <SelectionMode divRef={divRef} getNextCard={getNext} onTrue={onTrue} onFalse={onFalse} />;
            case Modes.Writing:
                return <WritingMode divRef={divRef} getNextCard={getNext} onTrue={onTrue} onFalse={onFalse} />;
            default:
                return <div style={{ margin: '50px' }}>Режим управления не существует</div>
        }
    }

    const componentNotFound = (
        <div className='centerText' >
            {isEmpty ? 'Карточек еще нет' : 'Все карточки изучены'}
        </div >
    );

    return (
        <LoaderLayout isLoading={isLoading} isNotFound={isNotFound} componentNotFound={componentNotFound}>
            <div className='cardsStudy'>
                <Card ref={divRef} card={currentCard} />
                {getMode(mode)}
            </div>
        </LoaderLayout>
    );
}