import './CardSettings.css'
import { ChangeEvent, useRef, useState } from 'react';
import { FileObject } from 'material-ui-dropzone'
import { Card } from '../Card/Card'
import { CardEntity } from "../../entities/Card";
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { CardWordValidators } from '../../validators/Validators';
import { CardWordErrorMessages } from '../../validators/ErrorMessage';
import { Button } from '@material-ui/core';
import { LoadingButton } from '../CustomButtons/LoadingButton';
import { TranslationButton } from '../CustomButtons/TranslationButton';

type CardSettingsProps = {
    card: CardEntity,
    textForSave: string,
    textForClose: string,
    onSave?: (card: CardEntity, img?: File) => Promise<void>,
    onClose: Function,
}

export default function CardSettings(props: CardSettingsProps) {
    const { card, textForSave, textForClose, onSave, onClose } = props;
    const [cardState, setCardState] = useState<CardEntity>(card);
    const cardRef = useRef<HTMLDivElement>(null);
    const img = useRef<File>();

    const [isLoading, setIsLoading] = useState(false);

    async function handleSave() {
        if (onSave) {
            setIsLoading(true);
            await onSave(cardState, img.current);
            onClose();
        }
    }

    function handleAddImage(images: FileObject[]) {
        setCardState({ ...cardState, imagePath: images[0].data?.toString() });
        img.current = images[0].file;
    }

    return (
        <div className='settingsBlock'>
            <div className='part1' style={{ margin: '20px' }}>
                <Card ref={cardRef} card={cardState}
                    onClick={() => cardRef.current?.classList.toggle('isFlipped')} />
            </div>
            <ValidatorForm className='part2' onSubmit={handleSave} instantValidate={false}>
                <TextValidator label='Слово' variant='outlined' type='text' name='word'
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setCardState({ ...cardState, question: e.target.value })}
                    value={cardState.question}
                    validators={CardWordValidators}
                    errorMessages={CardWordErrorMessages}
                />
                {/* <TranslationButton getText={() => cardState.question}
                    setText={(text: string) => setCardState(value => ({ ...value, answer: text }))} /> */}
                <TextValidator label='Перевод' variant='outlined' type='text' name='translation'
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setCardState({ ...cardState, answer: e.target.value })}
                    value={cardState.answer}
                    validators={CardWordValidators}
                    errorMessages={CardWordErrorMessages}
                />
                <ImageDropzone onAddImage={handleAddImage} />
                <LoadingButton className='button' type='submit' text={textForSave} loading={isLoading} />
                <Button className='button' onClick={() => onClose()} children={textForClose} />
            </ValidatorForm>
        </div >
    );
}